import 'bootstrap'
import 'select2/dist/js/select2.min'

export default {
  init() {
    $(document).on('click', '.yamm .dropdown-menu', function(e) {
      e.stopPropagation()
    })

    //burger
    $('.header__burger').click( function () {
      $('.mobile-nav').addClass('mobile-active');
      $('.header__burger').addClass('burger-active');
      $('html, body').addClass('bodyoff');
    });
    $('.mobile-nav__close').click( function () {
      $('.mobile-nav').removeClass('mobile-active');
      $('.header__burger').removeClass('burger-active');
      $('html, body').removeClass('bodyoff');
    });

    //select2
    $('.select-seek').select2({
      minimumResultsForSearch: -1,
      placeholder: {
        id: '0',
        text: '', //Should be text not placeholder
      },
    });
    $('.select-state').select2({
      minimumResultsForSearch: -1,
      placeholder: {
        id: '0',
        text: '', //Should be text not placeholder
      },
    });
    $('.select-drug').select2({
      minimumResultsForSearch: -1,
      placeholder: {
        id: '0',
        text: '', //Should be text not placeholder
      },
    });
    $('.select-health').select2({
      minimumResultsForSearch: -1,
      placeholder: {
        id: '0',
        text: '', //Should be text not placeholder
      },
    });

    $('.name-toggle').click(function(){
      if (!$(this).data('status')) {
        $(this).html('Hide details');
        $(this).data('status', true);
      }
      else {
        $(this).html('Show details');
        $(this).data('status', false);
      }
    });

    $('.name-toggle').click( function() {
      $(this).next().toggleClass('rotate')
    });
  },

  // JavaScript to be fired on all pages, after page specific JS is fired
  finalize() {
  },
};
